import { AlertLevelNames } from '@app/core/entity/alert.level';
import { StatusColor } from '@app/core/misc/status-color';
import {
  ActivityType,
  CompositeBaseType,
  CompositeBasePropertyType,
  CompositeType,
  AccountingCompositeType,
  SalaryCompositeType,
  IncomeTaxDeclarationCompositeType,
} from '../types';
import { chainHandler, createChainHandler } from '@app/core/misc/chain-handler';

const compositePropertyNames: Record<CompositeType, string[]> = {
  accounting: ['dags', 'bokf', 'kont', 'avst', 'rapp'],
  closing: ['best', 'forb', 'boks', 'uppr', 'arsr', 'arss', 'uppa', 'skic', 'bokr', 'bfrb'],
  incomestatement: ['eulo', 'eura', 'fora', 'konu', 'kuut', 'rest', 'sral'],
  incometaxdeclaration: ['uppi', 'uppb'],
  salaries: ['lonb', 'lone', 'lonu', 'ovri', 'stat', 'form', 'coll'],
  taxdeclaration: ['arbe', 'beta', 'betg', 'lonn', 'momd', 'momu', 'pers'],
};

const getCompositeColumnTypePropertyNames = (item: CompositeBaseType): string[] => compositePropertyNames[item.type];

const getCompositeColumnTypeObjects = (item: CompositeBaseType): CompositeBasePropertyType[] =>
  getCompositeColumnTypePropertyNames(item).reduce(
    (acc, propName) => [...acc, (item as Record<string, any>)[propName]],
    [],
  );

const isCompleted = (activity: ActivityType) => activity?.alertLevel?.name === AlertLevelNames.COMPLETED;

const hasAlertLevel = (activity: ActivityType) => !!activity?.alertLevel?.name;

const hasSameId = (oldActivity: ActivityType, newActivity: ActivityType) =>
  oldActivity?.id && newActivity?.id && oldActivity.id === newActivity.id;

const hasSameTaskId = (oldActivity: ActivityType, newActivity: ActivityType) =>
  oldActivity?.taskId && newActivity?.taskId && oldActivity.taskId === newActivity.taskId;

const hasSameSpanStart = (oldActivity: ActivityType, newActivity: ActivityType) =>
  oldActivity?.span?.start && newActivity?.span?.start && oldActivity?.span?.start === newActivity?.span?.start;

const hasSameSpanEnd = (oldActivity: ActivityType, newActivity: ActivityType) =>
  oldActivity?.span?.end && newActivity?.span?.end && oldActivity?.span?.end === newActivity?.span?.end;

const isSameActivity = (oldActivity: ActivityType, newActivity: ActivityType) => {
  if (hasSameId(oldActivity, newActivity)) {
    return true;
  }

  return (
    hasSameTaskId(oldActivity, newActivity) &&
    hasSameSpanStart(oldActivity, newActivity) &&
    hasSameSpanEnd(oldActivity, newActivity)
  );
};

const hasOwnProperty = (item: unknown, propertyName: string): boolean =>
  Object.prototype.hasOwnProperty.call(item, propertyName);

const isBokfComposite = (item: unknown): item is AccountingCompositeType => hasOwnProperty(item, 'bokf');

const isLonbComposite = (item: unknown): item is SalaryCompositeType => hasOwnProperty(item, 'lonb');

const isUppiComposite = (item: unknown): item is IncomeTaxDeclarationCompositeType => hasOwnProperty(item, 'uppi');

export const getStatusColor = (alertLevel: string) => StatusColor.getColorByAlertName(alertLevel);

export type UpdateActivityCallback<CompositeType extends CompositeBaseType = CompositeBaseType> = (
  newActivity: ActivityType,
  items: CompositeType[],
) => CompositeType[];

type CompositeHandlerContext<T extends CompositeBaseType> = {
  item: T;
  propName: string;
  newProperty: CompositeBasePropertyType;
  newActivity: ActivityType;
};

// Handlers for each special composite type
const bokfCompositeHandler = <T extends CompositeBaseType>() =>
  createChainHandler<CompositeHandlerContext<T>, T>((context) => {
    const { item, newActivity, propName, newProperty } = context;

    if (!isBokfComposite(item) || !newActivity.materialUpdate) {
      return null;
    }
    const accountingMaterial = { ...newActivity.materialUpdate };
    return {
      ...item,
      accountingMaterial,
      [propName]: newProperty,
      materialStatusColor: getStatusColor(newActivity.materialUpdate?.alertLevel?.name),
    };
  });

const lonbCompositeHandler = <T extends CompositeBaseType>() =>
  createChainHandler<CompositeHandlerContext<T>, T>((context) => {
    const { item, newActivity, propName, newProperty } = context;

    if (!isLonbComposite(item) || !newActivity.materialUpdate) {
      return null;
    }
    const paymentMaterial = { ...newActivity.materialUpdate };
    return {
      ...item,
      paymentMaterial,
      [propName]: newProperty,
      materialStatusColor: getStatusColor(newActivity.materialUpdate?.alertLevel?.name),
    };
  });

const uppiCompositeHandler = <T extends CompositeBaseType>() =>
  createChainHandler<CompositeHandlerContext<T>, T>((context) => {
    const { item, newActivity, propName, newProperty } = context;

    if (!isUppiComposite(item) || !newActivity.materialUpdate) {
      return null;
    }
    const declarationMaterial = newActivity.materialUpdate
      ? {
          materialReceivedAlertLevel: { ...newActivity.materialUpdate?.alertLevel },
          materialReceivedAt: newActivity.materialUpdate?.when,
        }
      : {
          materialReceivedAlertLevel: { description: '', name: '' },
          materialReceivedAt: null,
        };

    return {
      ...item,
      declarationMaterial,
      [propName]: newProperty,
      materialStatusColor: getStatusColor(newActivity.materialUpdate?.alertLevel?.name),
    };
  });

// Default handler for all other composite types
const defaultHandler = <T extends CompositeBaseType>() =>
  createChainHandler<CompositeHandlerContext<T>, T>((context) => {
    const { item, propName, newProperty } = context;
    return { ...item, [propName]: newProperty };
  });

export const updateActivityCallback =
  <CompositeType extends CompositeBaseType>(): UpdateActivityCallback<CompositeType> =>
  (newActivity: ActivityType, items: CompositeType[]): CompositeType[] => {
    let found = false;

    const compositePropUpdateHandler = chainHandler<CompositeHandlerContext<CompositeType>, CompositeType>([
      bokfCompositeHandler(),
      lonbCompositeHandler(),
      uppiCompositeHandler(),
      defaultHandler(),
    ]);

    return items.map((item) => {
      if (found) {
        return item;
      }

      const propNames = getCompositeColumnTypePropertyNames(item);
      // eslint-disable-next-line no-restricted-syntax
      for (const propName of propNames) {
        const property: CompositeBasePropertyType = (item as Record<string, any>)[propName];
        found = isSameActivity(property.activity, newActivity);
        if (found) {
          const newProperty: CompositeBasePropertyType = { ...property, activity: newActivity };
          const context: CompositeHandlerContext<CompositeType> = {
            item,
            propName,
            newProperty,
            newActivity,
          };

          return compositePropUpdateHandler.handle(context);
        }
      }

      return item;
    });
  };

export const isAllCompositeColumnTypeObjectsDone = (item: CompositeBaseType) =>
  getCompositeColumnTypeObjects(item).every(
    (x: { activity: ActivityType }) => isCompleted(x?.activity) || !hasAlertLevel(x?.activity),
  );

export const getAgentUrl = (item: CompositeBaseType, includeJwt = false): string => {
  const paths = getCompositeColumnTypePropertyNames(item)
    .map((propName) => (item as Record<string, any>)[propName].agentCall)
    .filter((agentCallPath) => Boolean(agentCallPath));

  let path: string = paths.length > 0 ? paths[0] : '';

  if (path && includeJwt) {
    path += `&jwt=${sessionStorage.getItem('jwt') || ''}`;
  }

  return path;
};
