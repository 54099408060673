import { Component, OnInit, OnDestroy } from '@angular/core';
import { SalaryCompositeType, CompositeListFilter, SalaryListParams, CompositeType } from '@app/core/state/types';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { CompositeListComponent } from '@app/shared/components/composite-list/composite-list.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { TodayDate } from '@app/shared/misc/today.date';

@Component({
  selector: 'app-salary-list',
  templateUrl: './salary.list.component.html',
})
export class SalaryListComponent
  extends CompositeListComponent<SalaryCompositeType, CompositeListFilter, SalaryListParams>
  implements OnInit, OnDestroy
{
  selectedMonth: string;
  selectedYear: string;
  selectedClientResponsibleUserId: number;
  selectedTaskUserId: number;
  includeAllDoneClients: boolean;
  columns: TableColumn[] = [
    { field: 'client', header: 'Klient' },
    { field: 'corporateIdentity', header: 'Orgnr', width: '9%' },
    { field: 'customerNumber', header: 'Kundnr', width: '5%' },
    { field: 'responsible', header: 'Klientansv', width: '5%' },
    { field: 'paymentDay', header: 'Löneutbet.dag', width: '7%' },
    { field: 'paymentMaterial.when', header: 'Material (Lönebesked)', width: '10%' },
    {
      field: 'lonb.activity.alertLevel.description',
      header: 'Lönebesked',
      width: '9%',
      fieldValue: (_fieldValue, rowData) => rowData?.lonb?.activity?.state?.description,
      additionalFilterFields: ['lonb.activity.state.description'],
    },
    {
      field: 'lonu.activity.alertLevel.description',
      header: 'Löneutbetalning',
      width: '9%',
      fieldValue: (_fieldValue, rowData) => rowData?.lonu?.activity?.state?.description,
      additionalFilterFields: ['lonu.activity.state.description'],
    },
    {
      field: 'lone.activity.alertLevel.description',
      header: 'Löneadministration',
      width: '9%',
      fieldValue: (_fieldValue, rowData) => rowData?.lone?.activity?.state?.description,
      additionalFilterFields: ['lone.activity.state.description'],
    },
    {
      field: 'form.activity.alertLevel.description',
      header: 'Fora lönerapp.',
      width: '9%',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.form?.activity?.state?.description,
      additionalFilterFields: ['form.activity.state.description'],
    },
    {
      field: 'coll.activity.alertLevel.description',
      header: 'Collectum lönerapp.',
      width: '9%',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.coll?.activity?.state?.description,
      additionalFilterFields: ['coll.activity.state.description'],
    },
    {
      field: 'stat.activity.alertLevel.description',
      header: 'Statistik SCB',
      width: '9%',
      fieldValue: (_fieldValue, rowData) => rowData?.stat?.activity?.state?.description,
      additionalFilterFields: ['stat.activity.state.description'],
    },
    {
      field: 'ovri.activity.alertLevel.description',
      header: 'Övrig rapportering',
      width: '9%',
      fieldValue: (_fieldValue, rowData) => rowData?.ovri?.activity?.state?.description,
      additionalFilterFields: ['ovri.activity.state.description'],
    },
    { field: 'taskUsers', header: 'UH', width: '5%', visible: false },
    { field: 'program', header: 'Program', width: '70px', sortable: false, exportable: false },
  ];

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  loadUserSettings(): void {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.SALARIES_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.selectedMonth = this.uss.loadSetting(
      UserSettingsStorage.SALARIES_MONTH,
      new TodayDate().getCurrentMonthAsString(),
    );
    this.selectedYear = this.uss.loadSetting(
      UserSettingsStorage.SALARIES_YEAR,
      new TodayDate().getCurrentYearAsString(),
    );
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.SALARIES_CLIENT_RESPONSIBLE_ID,
      0,
    );
    this.selectedTaskUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.SALARIES_USER_ID, 0);
    this.includeAllDoneClients = this.uss.loadSettingAsBoolean(
      UserSettingsStorage.SALARIES_INCLUDE_ALL_DONE_CLIENTS,
      true,
    );
  }

  saveUserSettings(): void {
    this.uss.saveSetting(UserSettingsStorage.SALARIES_MONTH, this.selectedMonth);
    this.uss.saveSetting(UserSettingsStorage.SALARIES_YEAR, this.selectedYear);
    this.uss.saveSetting(UserSettingsStorage.SALARIES_CLIENT_RESPONSIBLE_ID, `${this.selectedClientResponsibleUserId}`);
    this.uss.saveSetting(UserSettingsStorage.SALARIES_USER_ID, `${this.selectedTaskUserId}`);
    this.uss.saveSetting(UserSettingsStorage.SALARIES_INCLUDE_ALL_DONE_CLIENTS, `${this.includeAllDoneClients}`);
  }

  getFilterValue() {
    return { includeAllDoneClients: this.includeAllDoneClients };
  }

  getParamsValue() {
    return {
      responsibleId: this.selectedClientResponsibleUserId,
      executorId: this.selectedTaskUserId,
      year: this.selectedYear,
      periodIndex: new TodayDate().getIndexOfMonth(this.selectedMonth),
    };
  }

  getPaginatorTriggeredKey(): string {
    return UserSettingsStorage.SALARIES_NUMBER_OF_ROWS;
  }

  getCompositeType() {
    return CompositeType.salaries;
  }
}
