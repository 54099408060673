import { AccountingComposite } from '@app/core/entity/accounting.composite';
import { ActivityState } from '@app/core/entity/activitystate';
import { ClosingComposite } from '@app/core/entity/closing.composite';
import { Firm } from '@app/core/entity/firm';
import { Rule } from '@app/core/entity/rule';
import { IncomeTaxDeclarationComposite } from '@app/core/entity/incometaxdeclaration.composite';
import { SalaryComposite } from '@app/core/entity/salary.composite';
import { TaxDeclarationComposite } from '@app/core/entity/taxdeclaration.composite';
import { Type } from '@app/core/entity/type';
import { Activity, StateChangeEvent } from '../../entity/activity';
import { Client } from '../../entity/client';
import { Contact } from '../../entity/contact';
import { DeadlineRule } from '../../entity/deadlinerule';
import { FinancialYear } from '../../entity/financialyear';
import { Permission, PermissionGroup } from '../../entity/permission';
import { Span } from '../../entity/span';
import { Task } from '../../entity/task';
import { Role, RoleLabel, User } from '../../entity/user';
import { getStatusColor } from '../shared/shared';
import {
  ActivityStateType,
  ActivityType,
  ClientType,
  ClosingCompositeType,
  CompositeBasePropertyType,
  ClientTypeType,
  ContactType,
  DeadlineRuleType,
  FinancialYearType,
  FirmType,
  NameDescriptionType,
  PermissionGroupType,
  PermissionsType,
  PermissionType,
  RoleLabelType,
  RoleType,
  SimpleClientType,
  SimpleUserType,
  SpanType,
  StateChangeEventType,
  TaskType,
  UserType,
  RuleType,
  PrivilegeType,
  SalaryCompositeType,
  TaxDeclarationCompositeType,
  IncomeTaxDeclarationCompositeType,
  CompositeType,
  AccountingCompositeType,
} from '../types';

export interface Transformer<From = any, To = any> {
  transform: (from: From) => To;
}

export const toUserTransformer: Transformer<UserType, User> = {
  transform: ({ active, email, firm, id, initials, name, role, roleLabel, username, privilege, partnerProgram }) =>
    User.from({
      active,
      email,
      firm: { name: firm?.name, corporateIdentity: firm?.corporateIdentity },
      id,
      initials,
      name,
      role: Role[role],
      roleLabel: (RoleLabel as Record<string, RoleLabel>)[roleLabel],
      username,
      privilege: privilege ?? PrivilegeType.RW,
      partnerProgram,
    }),
};

export const toUserTypeTransformer: Transformer<User, UserType> = {
  transform: ({ active, email, firm, id, initials, name, role, username, privilege, teams, partnerProgram }) => ({
    active,
    email,
    firm: { name: firm?.name, corporateIdentity: firm?.corporateIdentity },
    id,
    initials,
    name,
    role: RoleType[role],
    roleLabel: (RoleLabelType as Record<string, RoleLabelType>)[role],
    username,
    privilege: privilege ?? PrivilegeType.RW,
    teams: teams ?? [],
    partnerProgram,
  }),
};

export const toPermissionsTransformer: Transformer<PermissionsType, Permission> = {
  transform: ({ clientId, id, permissionGroup, permissionKey, userId }) => ({
    clientId,
    id,
    permissionGroup: (PermissionGroup as Record<string, PermissionGroup>)[permissionGroup],
    permissionKey: (PermissionType as Record<string, PermissionType>)[permissionKey],
    userId,
  }),
};

export const toPermissionsTypeTransformer: Transformer<Permission, PermissionsType> = {
  transform: ({ clientId, id, permissionGroup, permissionKey, userId }) => ({
    clientId,
    id,
    permissionGroup: (PermissionGroupType as Record<string, PermissionGroupType>)[permissionGroup],
    permissionKey: (PermissionType as Record<string, PermissionType>)[permissionKey],
    userId,
  }),
};

export const toContactTransformer: Transformer<ContactType, Contact> = {
  transform: ({ clientId, comment, email, id, label, phone }) => ({ clientId, comment, email, id, label, phone }),
};

export const toContactTypeTransformer: Transformer<Contact, ContactType> = {
  transform: ({ clientId, comment, email, id, label, phone }) => ({ clientId, comment, email, id, label, phone }),
};

export const toSpanTransformer: Transformer<SpanType, Span> = {
  transform: ({ end = '', spanLabel = '', spanType = '', start = '', sameDay = false }) => ({
    end,
    spanLabel,
    spanType,
    start,
    sameDay,
  }),
};

export const toSpanTypeTransformer: Transformer<Partial<Span>, SpanType> = {
  transform: ({ end = '', spanLabel = '', spanType = '', start = '', sameDay = false }) => ({
    end,
    spanLabel,
    spanType,
    start,
    sameDay,
  }),
};

export const toFinancialYearsTransformer: Transformer<FinancialYearType, FinancialYear> = {
  transform: ({
    clientId,
    electronicCompilation,
    electronicTax,
    electronicVat,
    endDate,
    euCommerce,
    firmTaxRespite,
    firmVatRespite,
    id,
    largeCompany,
    span,
  }) => ({
    clientId,
    electronicCompilation,
    electronicTax,
    electronicVat,
    endDate,
    euCommerce,
    firmTaxRespite,
    firmVatRespite,
    id,
    largeCompany,
    span: span ? toSpanTransformer.transform(span) : null,
  }),
};

export const toFinancialYearsTypeTransformer: Transformer<FinancialYear, FinancialYearType> = {
  transform: ({
    clientId,
    electronicCompilation,
    electronicTax,
    electronicVat,
    endDate,
    euCommerce,
    firmTaxRespite,
    firmVatRespite,
    id,
    largeCompany,
    span,
  }) => ({
    clientId,
    electronicCompilation,
    electronicTax,
    electronicVat,
    endDate,
    euCommerce,
    firmTaxRespite,
    firmVatRespite,
    id,
    largeCompany,
    span: span ? toSpanTypeTransformer.transform(span) : null,
  }),
};

export const fromUserToSimpleUserTypeTransformer: Transformer<User, SimpleUserType> = {
  transform: ({ id, name, initials }) => ({ id, name, initials, simple: true }),
};

export const toSimpleUserTransformer: Transformer<SimpleUserType, User> = {
  transform: ({ id, name, initials }) => User.from({ id, name, initials, simple: true }),
};

export const toSimpleUserTypeTransformer: Transformer<UserType, SimpleUserType> = {
  transform: ({ id, name, initials }) => ({ id, name, initials, simple: true }),
};

export const fromClientToSimpleClientTypeTransformer: Transformer<Client, SimpleClientType> = {
  transform: ({ corporateIdentity, financialYears, customerNumber, id, name, responsible }) => ({
    corporateIdentity,
    currentFinancialYear: financialYears?.length ? financialYears[0] : null,
    customerNumber,
    id,
    name,
    responsible: responsible ? fromUserToSimpleUserTypeTransformer.transform(responsible) : null,
    simple: true,
  }),
};

export const toSimpleClientTransformer: Transformer<SimpleClientType, Client> = {
  transform: ({ corporateIdentity, customerNumber, id, name, responsible }) => {
    const client = new Client(name);
    client.corporateIdentity = corporateIdentity;
    client.customerNumber = customerNumber;
    client.id = id;
    client.responsible = responsible ? toSimpleUserTransformer.transform(responsible) : null;
    return client;
  },
};

export const toSimpleClientTypeTransformer: Transformer<ClientType, SimpleClientType> = {
  transform: ({ corporateIdentity, financialYears, customerNumber, id, name, responsible }) => ({
    corporateIdentity,
    currentFinancialYear: financialYears?.length ? financialYears[0] : null,
    customerNumber,
    id,
    name,
    responsible: responsible ? toSimpleUserTypeTransformer.transform(responsible) : null,
    simple: true,
  }),
};

export const toClientTransformer: Transformer<ClientType, Client> = {
  transform: ({
    accountant,
    address,
    advisoryTool,
    alertIdentity,
    archived,
    bank,
    businessArea,
    cellphone,
    city,
    cloudApiKey,
    contacts,
    corporateIdentity,
    country,
    customerNumber,
    deleteable,
    description,
    email,
    financialYears,
    id,
    name,
    otherInfo,
    permissions,
    phone,
    registrationDate,
    residence,
    responsible,
    saveFlag,
    signatory,
    specialRules,
    type,
    userAsString,
    userId,
    webpage,
    zipCode,
    foreignCorporateIdentity,
    teams,
    sustainabilityReporting,
  }) => ({
    accountant,
    address,
    advisoryTool,
    alertIdentity,
    archived,
    bank,
    businessArea,
    cellphone,
    city,
    cloudApiKey,
    contacts: contacts?.map(toContactTransformer.transform),
    corporateIdentity,
    country,
    customerNumber,
    deleteable,
    description,
    email,
    financialYears: financialYears?.map(toFinancialYearsTransformer.transform),
    id,
    name,
    otherInfo,
    permissions: permissions?.map(toPermissionsTransformer.transform),
    phone,
    registrationDate,
    residence,
    responsible: responsible ? toUserTransformer.transform(responsible) : null,
    saveFlag,
    signatory,
    specialRules,
    type,
    userAsString,
    userId,
    webpage,
    zipCode,
    foreignCorporateIdentity,
    teams: teams ? teams : [],
    sustainabilityReporting,
  }),
};

export const toClientTypeTransformer: Transformer<Client, ClientType> = {
  transform: ({
    accountant,
    address,
    advisoryTool,
    alertIdentity,
    archived,
    bank,
    businessArea,
    cellphone,
    city,
    cloudApiKey,
    contacts,
    corporateIdentity,
    country,
    customerNumber,
    deleteable,
    description,
    email,
    financialYears,
    id,
    name,
    otherInfo,
    permissions,
    phone,
    registrationDate,
    residence,
    responsible,
    saveFlag,
    signatory,
    specialRules,
    type,
    userAsString,
    userId,
    webpage,
    zipCode,
    foreignCorporateIdentity,
    teams,
    sustainabilityReporting,
  }) => ({
    accountant,
    address,
    advisoryTool,
    alertIdentity,
    archived,
    bank,
    businessArea,
    cellphone,
    city,
    cloudApiKey,
    contacts: contacts?.map(toContactTypeTransformer.transform),
    corporateIdentity,
    country,
    customerNumber,
    deleteable,
    description,
    email,
    financialYears: financialYears?.map(toFinancialYearsTypeTransformer.transform),
    id,
    name,
    otherInfo,
    permissions: permissions?.map(toPermissionsTypeTransformer.transform),
    phone,
    registrationDate,
    residence,
    responsible: responsible ? toUserTypeTransformer.transform(responsible) : null,
    saveFlag,
    signatory,
    specialRules,
    type,
    userAsString,
    userId,
    webpage,
    zipCode,
    foreignCorporateIdentity,
    teams: teams ? teams : [],
    sustainabilityReporting,
  }),
};

export const toClientTypeTypesTransformer: Transformer<Type, ClientTypeType> = {
  transform: ({ name = '', description = '' }) => ({ description, name }),
};

export const toNameDescriptionTransformer: Transformer<NameDescriptionType, { name: string; description: string }> = {
  transform: ({ name = '', description = '' }) => ({ description, name }),
};

export const toNameDescriptionTypeTransformer: Transformer<{ name: string; description: string }, NameDescriptionType> =
  {
    transform: ({ name = '', description = '' }) => ({ description, name }),
  };

export const toStateChangeEventTransformer: Transformer<StateChangeEventType, StateChangeEvent> = {
  transform: ({ changedBy, eventDate, id, state }) => ({
    changedBy,
    eventDate,
    id,
    state: state ? toNameDescriptionTransformer.transform(state) : null,
  }),
};

export const toStateChangeEventTypeTransformer: Transformer<StateChangeEvent, StateChangeEventType> = {
  transform: ({ changedBy, eventDate, id, state }) => ({
    changedBy,
    eventDate,
    id,
    state: state ? toNameDescriptionTypeTransformer.transform(state) : null,
  }),
};

export const toDeadlineRuleTransformer: Transformer<DeadlineRuleType, DeadlineRule> = {
  transform: ({ param, paramType, template, description }) => ({ param, paramType, template, description }),
};

export const toDeadlineRuleTypeTransformer: Transformer<DeadlineRule, DeadlineRuleType> = {
  transform: ({ param, paramType, template, description }) => ({ param, paramType, template, description }),
};

export const toRuleTypeTransformer: Transformer<Rule, RuleType> = {
  transform: ({ code, paramType, text }) => ({ code, paramType, text }),
};

export const toActivityTransformer: Transformer<ActivityType, Activity> = {
  transform: ({
    activeSpan,
    alertLevel,
    alert_level,
    comment,
    deadline,
    end_span,
    id,
    span,
    start_span,
    state,
    stateChangeEvents,
    stateColor,
    statusChangeDate,
    task,
    taskId,
    users,
    latestStateChangeEvent,
    unsavedId,
    materialUpdate,
  }) =>
    Activity.from({
      activeSpan: activeSpan ? toSpanTransformer.transform(activeSpan) : null,
      alert_level: alert_level ? toNameDescriptionTransformer.transform(alert_level) : null,
      alertLevel: alertLevel ? toNameDescriptionTransformer.transform(alertLevel) : null,
      comment,
      deadline,
      end_span,
      id,
      span: span ? toSpanTransformer.transform(span) : null,
      start_span,
      state: state ? toNameDescriptionTransformer.transform(state) : null,
      stateChangeEvents: stateChangeEvents?.map(toStateChangeEventTransformer.transform),
      stateColor,
      statusChangeDate,
      task: task ? toTaskTransformer.transform(task) : null,
      taskId,
      users: users?.map(toUserTransformer.transform),
      latestStateChangeEvent: latestStateChangeEvent
        ? toStateChangeEventTransformer.transform(latestStateChangeEvent)
        : null,
      unsavedId,
      materialUpdate,
    }),
};

export const toActivityTypeTransformer: Transformer<Activity, ActivityType> = {
  transform: ({
    activeSpan,
    alertLevel,
    alert_level,
    comment,
    deadline,
    end_span,
    id,
    span,
    start_span,
    state,
    stateChangeEvents,
    statusChangeDate,
    task,
    taskId,
    users,
    latestStateChangeEvent,
    unsavedId,
    materialUpdate,
  }) => {
    const alert_level_type = alert_level ? toNameDescriptionTypeTransformer.transform(alert_level) : null;
    const alertLevelType = alertLevel ? toNameDescriptionTypeTransformer.transform(alertLevel) : null;
    const stateColorType = getStatusColor(alert_level_type?.name || alertLevelType?.name);
    return {
      activeSpan: activeSpan ? toSpanTypeTransformer.transform(activeSpan) : null,
      alert_level: alert_level_type,
      alertLevel: alertLevelType,
      comment,
      deadline,
      end_span,
      id,
      span: span ? toSpanTypeTransformer.transform(span) : null,
      start_span,
      state: state ? toNameDescriptionTypeTransformer.transform(state) : null,
      stateChangeEvents: stateChangeEvents?.map(toStateChangeEventTypeTransformer.transform),
      stateColor: stateColorType,
      statusChangeDate,
      task: task ? toTaskTypeTransformer.transform(task) : null,
      taskId,
      users: users?.map(toUserTypeTransformer.transform),
      latestStateChangeEvent: latestStateChangeEvent
        ? toStateChangeEventTypeTransformer.transform(latestStateChangeEvent)
        : null,
      unsavedId,
      materialUpdate,
    };
  },
};

export const toTaskTransformer: Transformer<TaskType, Task> = {
  transform: ({
    agent,
    agentCall,
    archived,
    assignmentAgent,
    assignmentAgreement,
    assignmentId,
    assignmentName,
    client,
    client_id,
    client_name,
    code,
    comment,
    deadlineRule,
    deleteable,
    firmId,
    feedMode,
    id,
    parameterizedActivities,
    periodicity,
    preferredTemplate,
    preselectedCustomerResponsible,
    preselectedLoggedInUser,
    reminderDiff,
    selectablePeriodicities,
    selectableStates,
    selectable_states,
    span,
    startDiff,
    statusesAsString,
    type,
    users,
    usersAsString,
    warningDiff,
    taskGroupId,
  }) => ({
    agent,
    agentCall,
    archived,
    assignmentAgent,
    assignmentAgreement,
    assignmentId,
    assignmentName,
    client: client ? toClientTransformer.transform(client) : null,
    client_id,
    client_name,
    code,
    comment,
    deadlineRule: deadlineRule ? toDeadlineRuleTransformer.transform(deadlineRule) : null,
    deleteable,
    firmId,
    feedMode,
    id,
    parameterizedActivities: parameterizedActivities?.map(toActivityTransformer.transform),
    periodicity: periodicity ? toNameDescriptionTransformer.transform(periodicity) : null,
    preferredTemplate,
    preselectedCustomerResponsible,
    preselectedLoggedInUser,
    reminderDiff,
    selectable_states: selectable_states?.map(toNameDescriptionTransformer.transform),
    selectablePeriodicities: selectablePeriodicities?.map(toNameDescriptionTransformer.transform),
    selectableStates: selectableStates?.map(toNameDescriptionTransformer.transform),
    span: span ? toSpanTransformer.transform(span) : null,
    startDiff,
    statusesAsString,
    type,
    users: users?.map(toUserTransformer.transform),
    usersAsString,
    warningDiff,
    taskGroupId,
  }),
};

export const toTaskTypeTransformer: Transformer<Task, TaskType> = {
  transform: ({
    agent,
    agentCall,
    archived,
    assignmentAgent,
    assignmentAgreement,
    assignmentId,
    assignmentName,
    client,
    client_id,
    client_name,
    code,
    comment,
    deadlineRule,
    deleteable,
    firmId,
    feedMode,
    id,
    parameterizedActivities,
    periodicity,
    preferredTemplate,
    preselectedCustomerResponsible,
    preselectedLoggedInUser,
    reminderDiff,
    selectablePeriodicities,
    selectableStates,
    selectable_states,
    span,
    startDiff,
    statusesAsString,
    type,
    users,
    usersAsString,
    warningDiff,
    taskGroupId,
  }) => ({
    agent,
    agentCall,
    archived,
    assignmentAgent,
    assignmentAgreement,
    assignmentId,
    assignmentName,
    client: client ? toClientTypeTransformer.transform(client) : null,
    client_id,
    client_name,
    code,
    comment,
    deadlineRule: deadlineRule ? toDeadlineRuleTypeTransformer.transform(deadlineRule) : null,
    deleteable,
    firmId,
    feedMode,
    id,
    parameterizedActivities: parameterizedActivities?.map(toActivityTypeTransformer.transform),
    periodicity: periodicity ? toNameDescriptionTypeTransformer.transform(periodicity) : null,
    preferredTemplate,
    preselectedCustomerResponsible,
    preselectedLoggedInUser,
    reminderDiff,
    selectable_states: selectable_states?.map(toNameDescriptionTypeTransformer.transform),
    selectablePeriodicities: selectablePeriodicities?.map(toNameDescriptionTypeTransformer.transform),
    selectableStates: selectableStates?.map(toNameDescriptionTypeTransformer.transform),
    span: span ? toSpanTypeTransformer.transform(span) : null,
    startDiff,
    statusesAsString,
    type,
    users: users?.map(toUserTypeTransformer.transform),
    usersAsString,
    warningDiff,
    taskGroupId,
  }),
};

export const toFirmTransformer: Transformer<FirmType, Firm> = {
  transform: ({ id, corporateIdentity, name, address, city, email, phone, zipcode, users }) => ({
    id,
    corporateIdentity,
    name,
    address,
    city,
    email,
    phone,
    users,
    zipcode,
  }),
};

export const toActivtyStateTypeTransformer: Transformer<ActivityState, ActivityStateType> = {
  transform: ({ description, name }) => ({ description, name }),
};

export const toActivtyStateTransformer: Transformer<ActivityStateType, ActivityState> = {
  transform: ({ description, name }) => ({ description, name }),
};

export const toClosingCompositeTransformer: Transformer<ClosingCompositeType, ClosingComposite> = {
  transform: (value) => ClosingComposite.from(value),
};

export const toCompositeBasePropertyTransformer: Transformer<
  {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall?: string;
    shard?: { info: string };
  },
  CompositeBasePropertyType
> = {
  transform: ({ activity, agentCall, readyDate, shard, task }) => ({
    activity: activity ? toActivityTypeTransformer.transform(activity) : null,
    agentCall,
    readyDate,
    shard: shard ? { info: shard.info } : null,
    task: { comment: task.comment },
  }),
};

export const toClosingCompositeTypeTransformer: Transformer<ClosingComposite, ClosingCompositeType> = {
  transform: ({
    accountant,
    arsr,
    arss,
    best,
    bokr,
    boks,
    bfrb,
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    forb,
    mainSpan,
    responsible,
    skic,
    taskUsers,
    uppa,
    uppr,
  }) => ({
    type: CompositeType.closing,
    accountant,
    arsr: toCompositeBasePropertyTransformer.transform(arsr),
    arss: toCompositeBasePropertyTransformer.transform(arss),
    best: toCompositeBasePropertyTransformer.transform(best),
    bokr: toCompositeBasePropertyTransformer.transform(bokr),
    boks: toCompositeBasePropertyTransformer.transform(boks),
    bfrb: toCompositeBasePropertyTransformer.transform(bfrb),
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    forb: toCompositeBasePropertyTransformer.transform(forb),
    mainSpan: mainSpan
      ? {
          end: mainSpan.end,
          sameDay: mainSpan.sameDay,
          spanLabel: mainSpan.spanLabel,
          spanType: mainSpan.spanType,
          start: mainSpan.start,
        }
      : {
          end: '',
          sameDay: false,
          spanLabel: '',
          spanType: '',
          start: '',
        },
    responsible,
    skic: toCompositeBasePropertyTransformer.transform(skic),
    taskUsers,
    uppa: toCompositeBasePropertyTransformer.transform(uppa),
    uppr: toCompositeBasePropertyTransformer.transform(uppr),
  }),
};

export const toSalaryCompositeTypeTransformer: Transformer<SalaryComposite, SalaryCompositeType> = {
  transform: ({
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    lonb,
    lone,
    lonu,
    form,
    materialStatusColor,
    ovri,
    coll,
    paymentDay,
    paymentMaterial,
    responsible,
    stat,
    taskUsers,
  }) => ({
    type: CompositeType.salaries,
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    lonb: toCompositeBasePropertyTransformer.transform(lonb),
    lone: toCompositeBasePropertyTransformer.transform(lone),
    lonu: toCompositeBasePropertyTransformer.transform(lonu),
    form: toCompositeBasePropertyTransformer.transform(form),
    ovri: toCompositeBasePropertyTransformer.transform(ovri),
    coll: toCompositeBasePropertyTransformer.transform(coll),
    stat: toCompositeBasePropertyTransformer.transform(stat),
    materialStatusColor: materialStatusColor,
    paymentDay: paymentDay,
    paymentMaterial: paymentMaterial
      ? { when: paymentMaterial.when, alertLevel: paymentMaterial.alertLevel }
      : { when: '', alertLevel: { name: '', description: '' } },
    responsible,
    taskUsers,
  }),
};

export const toTaxDeclarationCompositeTypeTransformer: Transformer<
  TaxDeclarationComposite,
  TaxDeclarationCompositeType
> = {
  transform: ({
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    arbe,
    beta,
    betg,
    lonn,
    momd,
    momu,
    pers,
    mainSpan,
    mainSpanOrigin,
    responsible,
    taskUsers,
  }) => ({
    type: CompositeType.taxDeclaration,
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    arbe: toCompositeBasePropertyTransformer.transform(arbe),
    beta: toCompositeBasePropertyTransformer.transform(beta),
    betg: toCompositeBasePropertyTransformer.transform(betg),
    lonn: toCompositeBasePropertyTransformer.transform(lonn),
    momd: toCompositeBasePropertyTransformer.transform(momd),
    momu: toCompositeBasePropertyTransformer.transform(momu),
    pers: toCompositeBasePropertyTransformer.transform(pers),
    mainSpan: mainSpan
      ? {
          end: mainSpan.end,
          sameDay: false,
          spanLabel: mainSpan.spanLabel,
          spanType: mainSpan.spanType,
          start: mainSpan.start,
        }
      : {
          end: '',
          sameDay: false,
          spanLabel: '',
          spanType: '',
          start: '',
        },
    mainSpanOrigin: mainSpanOrigin,
    responsible,
    taskUsers,
  }),
};

export const toIncomeTaxDeclarationCompositeTypeTransformer: Transformer<
  IncomeTaxDeclarationComposite,
  IncomeTaxDeclarationCompositeType
> = {
  transform: ({
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    deadline,
    declarationMaterial,
    declarationPeriodCode,
    firmTaxRespite,
    electronicTax,
    uppb,
    uppi,
    mainSpan,
    materialStatusColor,
    responsible,
    taskUsers,
  }) => ({
    type: CompositeType.incomeTaxDeclaration,
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    deadline,
    declarationMaterial: declarationMaterial
      ? {
          materialReceivedAlertLevel: declarationMaterial.materialReceivedAlertLevel,
          materialReceivedAt: declarationMaterial.materialReceivedAt,
          materialSortValue: declarationMaterial.materialSortValue,
        }
      : { materialReceivedAlertLevel: { name: '', description: '' }, materialReceivedAt: '', materialSortValue: '' },
    declarationPeriodCode,
    firmTaxRespite,
    electronicTax,
    uppb: toCompositeBasePropertyTransformer.transform(uppb),
    uppi: toCompositeBasePropertyTransformer.transform(uppi),
    mainSpan: mainSpan
      ? {
          end: mainSpan.end,
          sameDay: false,
          spanLabel: mainSpan.spanLabel,
          spanType: mainSpan.spanType,
          start: mainSpan.start,
        }
      : {
          end: '',
          sameDay: false,
          spanLabel: '',
          spanType: '',
          start: '',
        },
    materialStatusColor,
    responsible,
    taskUsers,
  }),
};

export const toAccountingCompositeTypeTransformer: Transformer<AccountingComposite, AccountingCompositeType> = {
  transform: ({
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    accountingMaterial,
    avst,
    bokf,
    dags,
    kont,
    rapp,
    mainSpan,
    materialStatusColor,
    responsible,
    taskUsers,
  }) => ({
    type: CompositeType.accounting,
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    accountingMaterial: accountingMaterial
      ? {
          alertLevel: {
            description: accountingMaterial.alertLevel?.description,
            name: accountingMaterial.alertLevel?.name,
          },
          when: accountingMaterial.when,
        }
      : { alertLevel: { description: '', name: '' }, when: '' },
    avst: toCompositeBasePropertyTransformer.transform(avst),
    bokf: toCompositeBasePropertyTransformer.transform(bokf),
    dags: toCompositeBasePropertyTransformer.transform(dags),
    kont: toCompositeBasePropertyTransformer.transform(kont),
    rapp: toCompositeBasePropertyTransformer.transform(rapp),
    mainSpan: mainSpan
      ? {
          end: mainSpan.end,
          sameDay: false,
          spanLabel: mainSpan.spanLabel,
          spanType: mainSpan.spanType,
          start: mainSpan.start,
        }
      : {
          end: '',
          sameDay: false,
          spanLabel: '',
          spanType: '',
          start: '',
        },
    materialStatusColor,
    responsible,
    taskUsers,
  }),
};
