<app-table
  #table
  storageKey="notificationsTable"
  [columns]="columns"
  [data]="todos"
  [rowsPerPage]="defaultNumberOfRows"
  exportFilename="Aviseringar"
  (pageChange)="paginatorTriggered($event)">
  <app-table-filter>
    <app-team-multi-selector localStorageKey="notifications" (OnTeamsInit)="initEverything()"></app-team-multi-selector>
    <app-table-filter-item label="Klientansvarig">
      <app-user-selector [(userId)]="selectedResponsibleUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item>
      <div class="ml-4">Visa underlag* för perioden:</div>
    </app-table-filter-item>
    <app-table-filter-item label="Datum från">
      <app-calendar
        [(selectedDate)]="startDate"
        [style]="{ 'max-width': '150px' }"
        [defaultDate]="defaultStartDate"></app-calendar>
    </app-table-filter-item>
    <app-table-filter-item label="Till">
      <app-calendar [(selectedDate)]="endDate" [style]="{ 'max-width': '150px' }"></app-calendar>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button
        [loading]="loading"
        [disabled]="!isFilterDatesValid"
        (onClick)="loadTodoInformation(true)"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <div style="font-size: 12px">Senast uppdaterad: {{ lastUpdatedDate | date : 'yyyy-MM-dd HH:mm' }}</div>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [ngModel]="onlyShowRowsWithContent"
        (ngModelChange)="onOnlyShowRowsWithContentChange($event)"
        [label]="onlyShowRowsWithContentLabel"
        [disabled]="loading"></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.customerNumber" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        defaultClientInfoCell;
        context: { $implicit: rowData, content: rowData.client.customerNumber }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.corporateIdentity" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        defaultClientInfoCell;
        context: { $implicit: rowData, content: rowData.client.corporateIdentity }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.name" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        defaultClientInfoCell;
        context: { $implicit: rowData, content: rowData.client.name }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.UnhandledDocuments" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        agentCallCell;
        context: { $implicit: rowData, content: rowData.todo.UnhandledDocuments, task: 'REGI' }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.UnpaidSupplierInvoices" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        agentCallCell;
        context: { $implicit: rowData, content: rowData.todo.UnpaidSupplierInvoices, task: 'BETF' }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.PaymentsToRemediate" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        blappCallCell;
        context: { $implicit: rowData, content: rowData.todo.PaymentsToRemediate }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.AutoRegisteredInvoice" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        agentCallCell;
        context: { $implicit: rowData, content: rowData.todo.AutoRegisteredInvoice, task: 'LEVF' }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.AutoRegisteredReceipt" let-rowData="rowData">
    <ng-container
      *ngTemplateOutlet="
        agentCallCell;
        context: { $implicit: rowData, content: rowData.todo.AutoRegisteredReceipt, task: 'BOKV' }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.UncoupledQredTransactions" let-rowData="rowData">
    <p
      (click)="isLoggedInWithAllInclusiveService ? openBlappInNewTab(rowData.client) : undefined"
      [ngClass]="isLoggedInWithAllInclusiveService ? 'hover:underline cursor-pointer' : 'cursor-text'"
      class="text-black mb-0">
      {{ rowData.todo.UncoupledQredTransactions }}
    </p>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.TaxAccount" let-rowData="rowData">
    <ng-container [ngSwitch]="rowData?.todo?.TaxAccount">
      <div
        *ngSwitchCase="'INACTIVE'"
        (click)="selectedClient = rowData?.client; showSkvGetStartedDialog = true"
        class="cursor-pointer underline">
        Kom igång
      </div>
      <div
        *ngSwitchCase="'MISSING_PERMISSION'"
        (click)="selectedClient = rowData?.client; showPermissionInfoDialog = true"
        class="cursor-pointer underline">
        Aviseras ej
      </div>
      <div
        *ngSwitchCase="'MISSING_PROXY'"
        class="text-bl-red-500 truncate"
        pTooltip="Det gick inte att hämta information från Skatteverket. Kontrollera att firmatecknaren gett Björn Lundén AB (556293-9982) läsbehörighet till skattekontot."
        tooltipPosition="bottom">
        <a
          href="https://www.skatteverket.se/foretag/etjansterochblanketter/allaetjanster/tjanster/ombudochbehorigheter"
          target="_blank"
          class="text-bl-red-500 truncate">
          Ombudsbehörighet saknas
        </a>
      </div>
      <div
        *ngSwitchCase="'SERVER_ERROR'"
        class="text-bl-red-500 truncate"
        pTooltip="Det gick inte att hämta information från Skatteverket. Kontrollera att firmatecknaren gett Björn Lundén AB (556293-9982) läsbehörighet till skattekontot."
        tooltipPosition="bottom">
        <a
          href="https://www.skatteverket.se/foretag/etjansterochblanketter/allaetjanster/tjanster/ombudochbehorigheter"
          target="_blank"
          class="text-bl-red-500 truncate"
          >Tekniskt fel inträffade.</a
        >
      </div>
      <div *ngSwitchDefault>
        <p
          (click)="openTaxAccount()"
          class="cursor-pointer hover:underline mb-0"
          [ngClass]="{
            'text-bl-red-500': +rowData?.todo?.TaxAccount < 0,
            'text-black': +rowData?.todo?.TaxAccount >= 0
          }">
          {{ rowData?.todo?.TaxAccount | BLCurrency : false }} kr
        </p>
      </div>
    </ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-rowData="rowData">
    <div class="flex justify-center">
      <label (click)="openBlappInNewTab(rowData.client)" class="cursor-pointer mr-4">
        <img src="assets/blapp-subscription-type-icons/sub_type4.png" alt="Starta BL APP" class="inline w-6" />
      </label>
      <label (click)="callAgent(rowData.client)" class="cursor-pointer">
        <img [src]="'BL' | getIcon : !isLoggedInWithAllInclusiveService" alt="Starta programmet" class="inline" />
      </label>
    </div>
  </ng-template>
  <ng-template tableTemplate="emptyMessage">
    {{
      loading
        ? 'Listan uppdateras. Har du många klienter kan det ta några minuter så vänligen vänta...'
        : 'Inga klienter med molndatabasnyckel hittades'
    }}
  </ng-template>
  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal rader: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner listan för import till Excel"></p-button>
      </div>
    </div>
  </ng-template>

  <ng-template #defaultClientInfoCell let-rowData let-content="content">
    <a
      (click)="showExistingClient(rowData.client)"
      class="text-black cursor-pointer"
      [ngClass]="{ '!text-bl-red-500': !rowData.todo.success }">
      {{ content }}
    </a>
  </ng-template>
  <ng-template #agentCallCell let-rowData let-content="content" let-task="task">
    <p
      (click)="isLoggedInWithAllInclusiveService ? callAgent(rowData.client, task) : undefined"
      [ngClass]="isLoggedInWithAllInclusiveService ? 'hover:underline cursor-pointer' : 'cursor-text'"
      class="text-black mb-0">
      {{ content }}
    </p>
  </ng-template>
  <ng-template #blappCallCell let-rowData let-content="content">
    <p
      (click)="isLoggedInWithAllInclusiveService ? callBlapp() : undefined"
      [ngClass]="isLoggedInWithAllInclusiveService ? 'hover:underline cursor-pointer' : 'cursor-text'"
      class="text-black mb-0">
      {{ content }}
    </p>
  </ng-template>
</app-table>

<a href="#" id="agentCaller"></a>

<p-dialog header="Alla klientansvariga?" [(visible)]="showAllUserWarningDialog" [closable]="false" [draggable]="false">
  <p>Du har valt att visa aviseringar för alla klientansvariga. Detta kan ta lång tid. Vill du fortsätta?</p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="allUserWarningConfirm()">Ja</button>
    <button type="button" class="btn btn-warning" (click)="allUserWarningCancel()">Nej</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Kom igång med Skattekonto"
  [(visible)]="showSkvGetStartedDialog"
  [draggable]="false"
  contentStyleClass="pb-2-important">
  <p>
    För att kunna se skattekontosaldo i Byråstöd behöver du ha aktiverat det på din klient i
    <a href="https://app.bjornlunden.se" target="_blank">app.bjornlunden.se</a>.<br />
    Det gör du via Ekonomiöversikten eller under Bank & Betalning. Din klient måste ge Björn Lundén AB läsrättigheter
    till Skattekontot.
  </p>
  <p>
    Byråadministratör eller klientansvarig behöver också på klientkortet under
    <span
      (click)="showSkvGetStartedDialog = false; showExistingClient(selectedClient, 'Molntjänster')"
      class="cursor-pointer underline"
      >Molntjänster</span
    >
    aktivera visningen och styra vilka som får se saldot.
  </p>
  <p>Skattekontosaldot uppdateras varje natt via en hämtning från Skatteverket så du har det alltid uppdaterat!</p>
  <p class="mb-0">
    <a href="https://support.bjornlunden.se/guide/skattekonto-i-appbjorlundense" target="_blank">Läs mer här >></a>
  </p>
  <p-footer>
    <button type="button" class="btn btn-warning" (click)="showSkvGetStartedDialog = false">Stäng</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Skattekontosaldot aviseras ej"
  [(visible)]="showPermissionInfoDialog"
  [draggable]="false"
  contentStyleClass="pb-2-important">
  <p>
    Ser du inte skattekontosaldot beror det på en inställning av aviseringarna.<br />
    Den kan bara ändras av klientansvarig eller byråadministratörer. <br />
    Du hittar inställningen på klientkortet under fliken
    <span
      (click)="showPermissionInfoDialog = false; showExistingClient(selectedClient, 'Molntjänster')"
      class="cursor-pointer underline"
      >Molntjänster</span
    >.
  </p>
  <p-footer>
    <button type="button" class="btn btn-warning" (click)="showPermissionInfoDialog = false">Stäng</button>
  </p-footer>
</p-dialog>
