import { Component, OnDestroy, OnInit } from '@angular/core';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { NotificationClientTodo, NotificationResult } from '@app/core/services/notification.service.types';
import { ONLY_SHOW_ROWS_WITH_CONTENT_LABEL } from '@app/shared/misc/constants';
import { getIncludeFinishedLabel } from '@app/shared/misc/getIncludeFinishedLabel';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Client } from '../core/entity/client';
import { ClientService } from '../core/services/clients.service';
import { NotificationService } from '../core/services/notification.service';
import { UserService } from '../core/services/user.service';
import { ToastActions } from '../core/state/toast/toast.actions';
import { ClientType, UserType } from '../core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '../core/state/users/users.selectors';
import { UserSettingsStorage } from '../core/storage/user.settings.storage';
import { TableColumn } from '../shared/components/table/interfaces/table-column';
import { TodayDate } from '../shared/misc/today.date';
import { environment } from 'src/environments/environment';

enum WarningDialogResult {
  NOT_ANSWERED,
  YES,
  NO,
}

@Component({
  templateUrl: './notifications.component.html',
  providers: [TodayDate],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public allTodos: NotificationClientTodo[] = [];
  public todos: NotificationClientTodo[] = [];
  public loading = true;
  public selectedResponsibleUserId = 0;
  public selectedClient: Client = new Client('');
  public startDate: string;
  public endDate: string;
  public showAllUserWarningDialog = false;
  public showSkvGetStartedDialog = false;
  public showPermissionInfoDialog = false;
  public defaultNumberOfRows = 50;
  public columns: TableColumn[] = [
    { field: 'client.customerNumber', header: 'Kundnr', width: '150px', class: 'text-center white-space-normal' },
    { field: 'client.corporateIdentity', header: 'Orgnr', width: '150px', class: 'text-center white-space-normal' },
    { field: 'client.name', header: 'Namn', class: 'text-center white-space-normal' },
    { field: 'client.responsible.initials', header: 'KA', width: '4%', class: 'text-center white-space-normal' },
    { field: 'todo.UnhandledDocuments', header: 'Ohanterade dokument', class: 'text-center white-space-normal' },
    { field: 'todo.UnpaidSupplierInvoices', header: 'Obet. lev.fakturor', class: 'text-center white-space-normal' },
    { field: 'todo.PaymentsToRemediate', header: 'Bet. som kräver åtgärd', class: 'text-center white-space-normal' },
    {
      field: 'todo.AutoRegisteredInvoice',
      header: 'Automatreg. lev.fakturor *',
      class: 'text-center white-space-normal',
    },
    { field: 'todo.AutoRegisteredReceipt', header: 'Automatreg. kvitton *', class: 'text-center white-space-normal' },
    {
      field: 'todo.UncoupledQredTransactions',
      header: 'Okopplade Qred-trans. *',
      class: 'text-center white-space-normal',
    },
    {
      field: 'todo.SubmanPeppolIn',
      header: 'Inkommande e-faktura *',
      visible: false,
      class: 'text-center white-space-normal',
    },
    {
      field: 'todo.SubmanPeppolOut',
      header: 'Utgående e-faktura *',
      visible: false,
      class: 'text-center white-space-normal',
    },
    { field: 'todo.TaxAccount', header: 'Skattekontosaldo', class: 'text-center white-space-normal' },
    {
      field: 'program',
      header: 'Öppna med',
      width: '90px',
      sortable: false,
      class: 'text-center white-space-normal',
      exportable: false,
    },
  ];

  public onlyShowRowsWithContentLabel = ONLY_SHOW_ROWS_WITH_CONTENT_LABEL;
  public onlyShowRowsWithContent = false;

  private clients: Client[] = [];
  private uss = new UserSettingsStorage();
  private allUserWarningSubject = new BehaviorSubject(WarningDialogResult.NOT_ANSWERED);
  private lastSelectedResponsibleUserId: number;
  private onDestroySub: Subject<boolean>;

  get lastUpdatedDate(): Date | null {
    return this.notificationService.getLastUpdatedDate();
  }

  get isLoggedInWithAllInclusiveService() {
    return this.allInclusiveService.isLoggedInWithAnyAllInclusiveService;
  }

  get isFilterDatesValid() {
    return this.startDate && this.endDate;
  }

  get defaultStartDate() {
    return this.todayDate.subtractDays(7);
  }

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private todayDate: TodayDate,
    private store: Store<AppState>,
    private allInclusiveService: AllInclusiveService,
    private dialogService: DialogService,
  ) {
    this.loadUserSettings();
    this.onDestroySub = new Subject();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onDestroySub.next(true);
    this.onDestroySub.complete();
  }

  initEverything = () => forkJoin([this.loadUsers(), this.loadClients()]).subscribe(() => this.loadTodoInformation());

  loadTodoInformation(force = false) {
    if (!this.isFilterDatesValid) {
      return;
    }

    this.getLoadTodoInformationObservable(force)
      .pipe(
        first(),
        catchError(() => of([])), // catchError is partially handled by getLoadTodoInformationObservable so let's return an empty array)
      )
      .subscribe((result) => {
        this.saveUserSettings();
        this.lastSelectedResponsibleUserId = this.selectedResponsibleUserId;
        this.allTodos = result;
        this.todos = this.filterTodos(result);
        this.loading = false;
      });
  }

  callAgent(client: Client, task = '') {
    const agentCallerElement = <HTMLAnchorElement>document.getElementById('agentCaller');

    if (!this.allInclusiveService.isLoggedInWithAnyAllInclusiveService) {
      this.allInclusiveService.showMissingAllInclusiveServiceWarning();
      return;
    }

    this.clientService.getAgentUrl(client.id).subscribe(({ agentCall }) => {
      let href = agentCall;
      if (task) {
        href += `&task=${task}`;
      }

      const jwt = sessionStorage.getItem('jwt') || '';
      href += `&jwt=${jwt}`;

      agentCallerElement.href = href;
      agentCallerElement.click();
      return false;
    });
  }

  showExistingClient(client: Client, tabToOpen: string = null) {
    this.selectedClient = client;
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: client.id,
      store: this.store,
      tabToOpen,
    })
      .pipe(first())
      .subscribe((result) => {
        if (result?.client?.id && result?.client?.id !== -1) {
          this.loadClients()
            .pipe(takeUntil(this.onDestroySub))
            .subscribe(() => this.loadTodoInformation());
        }
      });
  }

  allUserWarningConfirm() {
    this.allUserWarningSubject.next(WarningDialogResult.YES);
  }

  allUserWarningCancel() {
    this.allUserWarningSubject.next(WarningDialogResult.NO);
  }

  paginatorTriggered(event: { rows: number }) {
    this.uss.saveSetting(UserSettingsStorage.NOTIFICATION_NUMBER_OF_ROWS, `${event.rows}`);
  }

  openTaxAccount() {
    if (!this.allInclusiveService.isLoggedInWithAnyAllInclusiveService) {
      this.allInclusiveService.showMissingAllInclusiveServiceWarning();
      return;
    }

    window.open(
      'https://www.skatteverket.se/foretag/etjansterochblanketter/allaetjanster/tjanster/skattekonto',
      '_blank',
    );
  }

  callBlapp() {
    if (!this.allInclusiveService.isLoggedInWithAnyAllInclusiveService) {
      this.allInclusiveService.showMissingAllInclusiveServiceWarning();
      return;
    }

    window.open('https://app.bjornlunden.se/', '_blank');
  }

  onOnlyShowRowsWithContentChange(onlyShowRowsWithContent: boolean) {
    this.onlyShowRowsWithContent = onlyShowRowsWithContent;
    this.uss.saveSetting(UserSettingsStorage.NOTIFICATION_ONLY_SHOW_ROWS_WITH_CONTENT, String(onlyShowRowsWithContent));
    this.todos = this.filterTodos(this.allTodos);
  }

  openBlappInNewTab(client: ClientType) {
    const blappUrl = `${environment.blappWeb}/${client.cloudApiKey || ''}`;
    window.open(blappUrl, '_blank');
  }

  private loadUserSettings() {
    this.selectedResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.NOTIFICATION_RESPONSIBLE_USER,
      -1, // default when not set in storage
    );

    this.allUserWarningSubject.next(
      this.uss.loadSettingAsNumber(
        UserSettingsStorage.NOTIFICATION_WARNING_DIALOG_RESULT,
        WarningDialogResult.NOT_ANSWERED,
      ),
    );
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.NOTIFICATION_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );

    const oneWeekBackDate = this.todayDate.subtractDays(7);
    const defaultStartDate = this.todayDate.getIsoFormattedDate(oneWeekBackDate);
    const defaultEndDate = this.todayDate.getTodayAsString();

    this.startDate = this.uss.loadSetting(UserSettingsStorage.NOTIFICATION_START_DATE, defaultStartDate);
    this.endDate = this.uss.loadSetting(UserSettingsStorage.NOTIFICATION_END_DATE, defaultEndDate);
    this.onlyShowRowsWithContent = this.uss.loadSettingAsBoolean(
      UserSettingsStorage.NOTIFICATION_ONLY_SHOW_ROWS_WITH_CONTENT,
      false,
    );
  }

  private saveUserSettings() {
    this.uss.saveSetting(UserSettingsStorage.NOTIFICATION_RESPONSIBLE_USER, `${this.selectedResponsibleUserId}`);

    // if the user has answered YES on the warning, then we store that so the user don't have to answer again.
    if (this.allUserWarningSubject.getValue() === WarningDialogResult.YES) {
      this.uss.saveSetting(UserSettingsStorage.NOTIFICATION_WARNING_DIALOG_RESULT, `${WarningDialogResult.YES}`);
    }

    this.uss.saveSetting(UserSettingsStorage.NOTIFICATION_START_DATE, this.startDate);
    this.uss.saveSetting(UserSettingsStorage.NOTIFICATION_END_DATE, this.endDate);
  }

  private loadUsers() {
    const allUsers$ = this.store.select(UserSelectors.activeUsersWithAll).pipe(
      filter((users) => users.length > 0),
      take(1),
    );

    return forkJoin([this.userService.getCurrentUser(), allUsers$]).pipe(
      map(([currentUser, users]) => {
        const existsUserInList = (list: UserType[], userId: number) =>
          list.some((user: UserType) => user.id === userId);
        this.selectedResponsibleUserId =
          this.selectedResponsibleUserId >= 0 ? this.selectedResponsibleUserId : currentUser.id;

        if (!existsUserInList(users, this.selectedResponsibleUserId)) {
          this.selectedResponsibleUserId = currentUser.id;
        }

        this.lastSelectedResponsibleUserId = this.selectedResponsibleUserId;
        return true;
      }),
    );
  }

  private loadClients() {
    const hasCloudApiKey = (client: Client) => !!client.cloudApiKey;
    const isArchived = (client: Client) => client.archived;

    return this.clientService.getAllClients().pipe(
      map((clients: Client[]) => {
        this.clients = clients.filter((client) => hasCloudApiKey(client) && !isArchived(client));
        return true;
      }),
    );
  }

  private getLoadTodoInformationObservable(force: boolean): Observable<NotificationClientTodo[]> {
    const loadTodosForResponsibleUser$ = of(true).pipe(
      tap(() => {
        this.loading = true;
        this.todos = [];
      }),
      switchMap(() =>
        this.notificationService.loadTodoInformationForResponsibleUser(
          this.selectedResponsibleUserId,
          this.startDate,
          this.endDate,
          force,
        ),
      ),
      map((result) => this.mapToClientTodoComposite(result)),
      map((result) => this.sortClientTodoComposite(result)),
      tap((result) => {
        if (result.some((composite) => !composite.todo.success)) {
          this.store.dispatch(
            ToastActions.showErrorMessage({
              summary: 'Gick inte hämta aviseringar för en eller flera klienter',
              detail: 'Kontrollera och ändra eller ta bort molndatabasnyckeln på klienter markerade med röd färg.',
            }),
          );
        }
      }),
    );

    if (!this.isAllUsersSelected(this.selectedResponsibleUserId)) {
      return loadTodosForResponsibleUser$;
    }

    if (this.allUserWarningSubject.getValue() === WarningDialogResult.NOT_ANSWERED) {
      this.showAllUserWarningDialog = true;
    }

    return this.allUserWarningSubject.asObservable().pipe(
      // waiting for an answer in the dialog
      filter((dialogResult: WarningDialogResult) => dialogResult !== WarningDialogResult.NOT_ANSWERED),
      take(1),
      switchMap((dialogResult: WarningDialogResult) => {
        this.showAllUserWarningDialog = false;

        if (dialogResult === WarningDialogResult.NO) {
          // reset the dialog result to not answered and change back to last selected user
          this.allUserWarningSubject.next(WarningDialogResult.NOT_ANSWERED);
          // this.loading = false;
          this.selectedResponsibleUserId = this.lastSelectedResponsibleUserId;
          return EMPTY;
        }

        return loadTodosForResponsibleUser$;
      }),
    );
  }

  private mapToClientTodoComposite(todoResult: NotificationResult[]): NotificationClientTodo[] {
    const defaultTodo = {
      UnhandledDocuments: 0,
      UnpaidSupplierInvoices: 0,
      UnpaidSupplierInvoicesInSEK: 0,
      UnpaidSupplierInvoicesInNonSEK: 0,
      PaymentsToRemediate: 0,
      AutoRegisteredInvoice: 0,
      AutoRegisteredReceipt: 0,
      UncoupledQredTransactions: 0,
      SubmanPeppolIn: 0,
      SubmanPeppolOut: 0,
      TaxAccount: 'INACTIVE',
    };

    return todoResult.reduce((acc, r) => {
      const client = this.clients.find((client) => client.id === r.clientId);
      if (!client) {
        return acc;
      }

      const todo = { ...defaultTodo, ...r.data, success: r.success };
      todo.UnpaidSupplierInvoices = todo.UnpaidSupplierInvoicesInSEK + todo.UnpaidSupplierInvoicesInNonSEK;

      acc = [...acc, { client, todo }];
      return acc;
    }, []);
  }

  private sortClientTodoComposite(list: NotificationClientTodo[]) {
    list.sort((a, b) => {
      let sortResult = b.todo.UnhandledDocuments - a.todo.UnhandledDocuments;

      if (sortResult === 0) {
        sortResult = b.todo.UnpaidSupplierInvoices - a.todo.UnpaidSupplierInvoices;
      }

      if (sortResult === 0) {
        sortResult = b.todo.PaymentsToRemediate - a.todo.PaymentsToRemediate;
      }

      if (sortResult === 0) {
        sortResult = b.todo.AutoRegisteredInvoice - a.todo.AutoRegisteredInvoice;
      }

      if (sortResult === 0) {
        sortResult = b.todo.AutoRegisteredReceipt - a.todo.AutoRegisteredReceipt;
      }

      if (sortResult === 0) {
        const aValue = a.todo.TaxAccount;
        const bValue = b.todo.TaxAccount;
        sortResult = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      }

      if (sortResult === 0) {
        sortResult = b.todo.SubmanPeppolIn - a.todo.SubmanPeppolIn;
      }

      if (sortResult === 0) {
        sortResult = b.todo.SubmanPeppolOut - a.todo.SubmanPeppolOut;
      }

      return sortResult;
    });

    return list;
  }

  private isAllUsersSelected(userId: string | number) {
    return +userId === 0;
  }

  private filterTodos(todos: NotificationClientTodo[]) {
    this.onlyShowRowsWithContentLabel = getIncludeFinishedLabel({
      label: ONLY_SHOW_ROWS_WITH_CONTENT_LABEL,
      completeList: todos,
      predicate: this.filterTodoPredicate,
    });

    if (!this.onlyShowRowsWithContent) {
      return todos;
    }

    return todos.filter(this.filterTodoPredicate);
  }

  // eslint-disable-next-line complexity
  private filterTodoPredicate = ({ todo }: NotificationClientTodo): boolean =>
    todo.AutoRegisteredInvoice > 0 ||
    todo.AutoRegisteredReceipt > 0 ||
    todo.PaymentsToRemediate > 0 ||
    todo.UnhandledDocuments > 0 ||
    todo.UnpaidSupplierInvoices > 0 ||
    todo.SubmanPeppolIn > 0 ||
    todo.SubmanPeppolOut > 0 ||
    todo.TaxAccount !== 'INACTIVE';
}
