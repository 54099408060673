export const environment = {
  production: true,
  blauth: {
    clientId: 'bl-firm-support',
    scope: 'BL_T_A BL_ALL_INCLUSIVE BL_CLIENT_MANAGER',
    redirectURI: 'https://byrastod.bjornlunden.se/auth',
    env: '', // empty == production
  },
  serverUrl: 'https://byrastod.bjornlunden.se/BLbs/',
  loginUrl: 'https://byrastod.bjornlunden.se/login',
  documentUrl: 'https://dokumentgenerator.bjornlunden.se',
  blappWeb: 'https://app.bjornlunden.se',
  version: '6.17.0',
  name: 'Production',
  termsOfAggrementUrl: 'https://www.bjornlunden.se/program/avtalsvillkor-redovisningsbyraer',
  sdkKey: 'Iy_aCNVU7E6IGx-j0R9XvQ/1FLmDu8phU67wrs6ZcqB1w',
  sustainabiltyUrl: 'https://app.wellfish.se',
  messagingUrl: 'wss://centrifugo.blinfo.se/connection/websocket',
  messagingNamespace: 'byrastod-prod',
  forms: {
    giveFeedback: 'Lk2lDQdG',
  },
};
