import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { PartnerFirm } from '@app/core/state/types/partner.types';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'app-partnerprogram-faq',
  styles: [
    `
      .app-partnerprogram-faq-accordion.p-accordion .p-accordion-tab {
        margin-bottom: 5px !important;
      }
      .app-partnerprogram-faq-accordion.p-accordion .p-accordion-header .p-accordion-header-link {
        background-color: var(--bg-white) !important;
        font-weight: 400 !important;
      }
      .app-partnerprogram-faq-accordion.p-accordion .p-accordion-header .p-accordion-header-link .p-icon-wrapper {
        order: 1;
        margin-left: auto;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  template: `
    <p-accordion styleClass="mt-5 app-partnerprogram-faq-accordion">
      <p-accordionTab header="Hur går byrån upp i nivå?">
        <p>
          Partnerbyrån tillhör en nivå som bestäms av antalet <strong>aktiva BL Pro-</strong> och
          <strong>BL Essential-paket</strong> kopplade till byråns kunder. Partnerbyrån går upp i nivå i
          Partnerprogrammet genom att skaffa fler kunder med BL Pro- och BL Essential-paket. Partnernivån stäms av vid
          varje månadsskifte, och byrån får sin aktuella nivå bestämd för den kommande månaden.
        </p>
      </p-accordionTab>
      <p-accordionTab header="När betalas återbäringen ut?" tabStyleClass="border-t border-bl-grey-200">
        <p>
          Återbäringen för kopplade <strong>BL Pro-paket</strong> betalas ut månadsvis i efterskott. Under BL
          Pro-paketets första 12 månader är återbäringen 30% av det belopp vi fakturerar slutkunden eller partnern för
          paketet. Från och med månad 13 är återbäringen 10—20%, beroende på byråns partnernivå inom intervallet
          <strong>Järn-Platina</strong>, i enlighet med tabellen i fliken <strong>Förmånsnivåer</strong>.
        </p>
      </p-accordionTab>
      <p-accordionTab header="Var hittar jag mina förmåner?" tabStyleClass="border-t border-bl-grey-200">
        <p>
          Alla förmåner finns listade i
          <strong>
            <a href="https://www.bjornlunden.se/redovisningsbyr%C3%A5/partnerprogram/policy__8985" target="_blank">
              Villkor — Bjorn Lundens Partnerprogram
            </a>
          </strong>
          , samt under fliken <strong class="cursor-pointer"><a (click)="openTab.emit(1)">Förmånsnivåer</a></strong>
        </p>
        <p *ngIf="firm?.level?.value >= 1 || firm?.level?.value === 0">
          Mer information samt länkar till varje förmån hittar du under fliken
          <strong class="cursor-pointer"><a (click)="openTab.emit(2)">Resurser</a></strong>
        </p>
      </p-accordionTab>
      <p-accordionTab
        header="Var hittar jag partnerloggan Bjorn Lunden Byråpartner?"
        tabStyleClass="border-t border-bl-grey-200"
        *ngIf="isPartner">
        <p>
          När du blivit partner med oss kan du ladda ner
          <a
            href="https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/nedladdning-partnerbadge__9271"
            target="_blank"
            >partnerloggan</a
          >
          för att använda i din marknadsföring.
        </p>
      </p-accordionTab>
      <p-accordionTab header="Hur fungerar leads?" tabStyleClass="border-t border-bl-grey-200">
        <p>
          Som partner på nivå <strong>Platina</strong> i Partnerprogrammet blir byrån exponerad för potentiella kunder.
          Inkommande förfrågningar till Bjorn Lunden från företag som söker en redovisningsbyrå hänvisas direkt till
          partnern, som får en icke-exklusiv rätt att bearbeta kunden för att sälja sina redovisningstjänster.
        </p>
      </p-accordionTab>
    </p-accordion>
  `,
  standalone: true,
  imports: [CommonModule, AccordionModule],
})
export class PartnerProgramFaqComponent {
  @Input() isPartner: boolean;
  @Input() firm: PartnerFirm | null;
  @Output() openTab: EventEmitter<number> = new EventEmitter();
}
